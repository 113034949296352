import { throttle } from 'yggdrasil/src/cfLodash'
import {
  CartOperation,
  UpsertCartOperation,
  RemoveCartOperation,
  ReplaceCartOperation,
  Stores,
  CartData,
  CartItemDataIdentifier,
  CartItemData,
  SetCartOperation,
  ChangeOrderIdOperation,
  IncrementCartOperation,
  DecrementCartOperation,
} from './types'
import { OrderId } from 'src/Elements/types'
import { atom } from 'nanostores'
import { sleepMs } from '../Utils/general'
import { uuidv4 } from 'javascript/lander/cf_utils'

let unsynchronizedEvents: CartOperation[] = []
export const syncingCart = atom(false)
let lastCartBeforeSync: CartData = null

window.addEventListener('beforeunload', async (evt) => {
  if (hasPendingOperations()) {
    // NOTE: We can't set a custom message here because the browser will ignore it
    // There are browser security reasons for that. But they provide good default
    // messages for each situation
    const ok = confirm()
    if (!ok) {
      evt.preventDefault()
    }
  }
})

export function hasPendingOperations(): boolean {
  return syncingCart.get() || unsynchronizedEvents.length > 0
}

export function closeCart(): void {
  const cartElement = document.querySelector('[data-page-element="CartReviewModal"]')
  const cartElementModal = (cartElement as any).cf2_instance.getComponent('ModalSidebar/V1')
  cartElementModal.hide()
}

export function openCart(): void {
  const cartElement = document.querySelector('[data-page-element="CartReviewModal"]')
  const cartElementModal = (cartElement as any).cf2_instance.getComponent('ModalSidebar/V1')
  cartElementModal.show()
}

export async function waitPendingOperations(): Promise<boolean> {
  return new Promise((resolve) => {
    const syncingCartLinsten = syncingCart.listen(() => {
      if (!hasPendingOperations()) {
        resolve(true)
        syncingCartLinsten()
      }
    })
  })
}

async function syncCartWithServer(): Promise<CartData | null> {
  let response: Response
  if (syncingCart.get()) {
    // NOTE: If the cart is already beeing synced we have to reschedule the sync, otherwise
    // we may have some pending operations that will not being sent until the next operations
    // the user does.
    return syncCartWithServerThrottled()
  }
  syncingCart.set(true)
  const operations = unsynchronizedEvents
  // NOTE: Since JS is single threaded we  are ok just clearing this global list
  unsynchronizedEvents = []
  const max_retries = 3

  // TODO implement using CFFetcher with retries...
  for (let i = 0; i < max_retries; i++) {
    try {
      const loaded_product_ids = Object.keys(globalThis.globalResourceData?.productsById ?? {}).map(Number)
      response = await fetch('/user_pages/api/v1/carts/operations', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operations, loaded_product_ids }),
      })
      if (response.ok) break
    } catch (error) {
      console.warn('[Cart] Error syncing cart with server', error)
    }

    const status = response?.status ?? 0
    if (status === 409) {
      const retryAfter = Number(response.headers.get('Retry-After') ?? 1000)
      await sleepMs(retryAfter)
    } else if (status >= 400 && status < 500) {
      // NOTE: We don't want to retry on 4xx errors because it will just fail
      // again as there are some business logic / bug that is triggering this
      break
    } else {
      // NOTE: Some socket, network error or 500
      await sleepMs(1000 * (i + 1))
    }
    console.log(`[Cart] ${response?.status ?? 0} - Retrying sync operations`)
  }

  if (!response || !response.ok) {
    console.warn('[Cart] Error syncing cart with server', response)
    // TODO(Zara): After we retry some times we may not be able to sync the operations with
    // the server. In that case we may want to show some message or alert to the user (?)

    // TODO(Zara): I am still not sure how to handle it properly. We retried when we could
    // But in some situation there is an operation that is not being accept or the server is really
    // out. In this case we first try to restore to the last state before all the stacked operations.
    if (lastCartBeforeSync) {
      globalThis.globalResourceData.cart = { ...lastCartBeforeSync }
      Cart.stores.cartData.set(lastCartBeforeSync)
    }
    lastCartBeforeSync = null

    const flashErrorElement = document.createElement('div')
    flashErrorElement.className = 'alert alert-error'
    flashErrorElement.innerText = 'We were unable to update your cart.'
    // TODO: improve error message based of status code and on server error message
    // when available.
    document.body.append(flashErrorElement)
    flashErrorElement.onanimationend = () => {
      document.body.removeChild(flashErrorElement)
    }
  } else {
    let newCartData: CartData
    try {
      newCartData = (await response.json()) as CartData
    } catch (e) {
      console.error('[Cart] Error parsing response as json', e, response)
    }

    if (unsynchronizedEvents.length == 0) {
      globalThis.registerProductsIndexes(newCartData?.products_diff ?? [])
      globalThis.globalResourceData.cart = { ...newCartData }
      // NOTE: nanostore dispatch this operation to all listeners synchronously
      // So if we catch the error in here we would be supressing errors that are
      // happening in the listeners. That would obscure the error and make it harder
      // to debug
      Cart.stores.cartData.set({ ...newCartData })
    }
  }
  syncingCart.set(false)
}

const syncCartWithServerThrottled = throttle(syncCartWithServer, 500, { leading: false })

export class Cart {
  static stores: Stores = {}
  static subscribers: ((ops: CartOperation[]) => void)[]
  static operations: CartOperation[] = []
  static scheduledUpdate: boolean

  static initialize(): void {
    const initialCart = this.getInitialCartData()
    this.stores.cartData = atom(initialCart)
    this.subscribers = []
    this.operations = []
    this.scheduledUpdate = false
  }

  static listenToEvents(eventHandler: (ops: CartOperation[]) => void): void {
    this.subscribers.push(eventHandler)
  }

  static getInitialCartData(): CartData {
    return globalThis.globalResourceData.cart ?? { items: [], summary: { display_sub_total: '', total_quantity: 0 } }
  }

  static lineItemUniqueId(item: CartItemData): string {
    return `${item.product_id}-${item.variant_id}-${item.price_id}`
  }

  static findItemByUniqueId(uniqueId: string): CartItemData {
    return this.stores.cartData.get().items.find((item) => this.lineItemUniqueId(item) === uniqueId)
  }

  static findItemByProductId(productId: string): CartItemData {
    return this.stores.cartData.get().items.find((item) => item.product_id == productId)
  }

  static isItemRecurring(item: CartItemData): boolean {
    return globalThis.globalResourceData.pricesById[item.price_id].is_recurring
  }

  static compareItems(item1: CartItemDataIdentifier, item2: CartItemDataIdentifier): boolean {
    return (
      item1.price_id == item2.price_id && item1.product_id == item2.product_id && item1.variant_id == item2.variant_id
    )
  }

  static removeItem(cartData: CartData, eventItem: CartItemDataIdentifier, updatePerformed: boolean): boolean {
    const cartItems = cartData.items
    const index = cartItems.findIndex((item) => this.compareItems(item, eventItem))
    if (index > -1) {
      updatePerformed = true
      cartData.items.splice(index, 1)
    }
    return updatePerformed
  }

  static queueOperation(operation: CartOperation): void {
    this.operations.push(operation)
    if (this.scheduledUpdate) return
    this.scheduledUpdate = true
    queueMicrotask(() => {
      this.dispatchEvents()
      this.scheduledUpdate = false
    })
  }

  static setOperation(items: CartItemData | CartItemData[]): SetCartOperation {
    items = Array.isArray(items) ? items : [items]
    const operation: SetCartOperation = {
      type: 'set',
      items,
    }
    this.queueOperation(operation)
    return operation
  }

  static upsertOperation(item: CartItemDataIdentifier, quantity = 1): UpsertCartOperation {
    if (quantity == 0) {
      if (!window.confirm('Are you sure you want to remove this item from your cart?')) return
    }
    const operation: UpsertCartOperation = {
      type: 'upsert',
      item,
      quantity,
    }
    this.queueOperation(operation)
    return operation
  }

  static incrementOperation(item: CartItemDataIdentifier): IncrementCartOperation {
    const operation: IncrementCartOperation = {
      type: 'increment',
      item,
    }
    this.queueOperation(operation)
    return operation
  }

  static decrementOperation(item: CartItemDataIdentifier): DecrementCartOperation {
    const cartItems = this.stores.cartData.get().items
    const cartItem = cartItems.find((i) => this.compareItems(i, item))
    if (cartItem.quantity == 1) {
      if (!window.confirm('Are you sure you want to remove this item from your cart?')) {
        return
      }
    }
    const operation: DecrementCartOperation = {
      type: 'decrement',
      item,
    }
    this.queueOperation(operation)
    return operation
  }

  static removeOperation(item: CartItemDataIdentifier, skipConfirmation = false): RemoveCartOperation {
    if (!skipConfirmation && !window.confirm('Are you sure you want to remove this item from your cart?')) {
      return
    }
    const operation: RemoveCartOperation = {
      type: 'remove',
      item,
    }
    this.queueOperation(operation)
    return operation
  }

  static replaceOperation(item: CartItemDataIdentifier, newItem: CartItemData): ReplaceCartOperation {
    const operation: ReplaceCartOperation = {
      type: 'replace',
      item,
      newItem,
    }
    this.queueOperation(operation)
    return operation
  }

  static changeOrderIdOperation(orderId: OrderId): ChangeOrderIdOperation {
    const operation: ChangeOrderIdOperation = {
      type: 'changeOrderId',
      orderId,
    }
    this.queueOperation(operation)
    return operation
  }

  static dispatchEvents(): void {
    const cartData = this.stores.cartData.get() as CartData

    if (!lastCartBeforeSync) {
      lastCartBeforeSync = structuredClone(cartData)
    }

    let updatePerformed = false
    this.operations.forEach((event) => {
      switch (event.type) {
        case 'changeOrderId': {
          if (cartData.order_id != event.orderId) {
            updatePerformed = true
            cartData.order_id = event.orderId
          }
          break
        }
        case 'set': {
          updatePerformed = true
          cartData.items = event.items
          break
        }
        case 'increment': {
          const cartItems = cartData.items
          const index = cartItems.findIndex((item) => this.compareItems(item, event.item))
          const { item } = event
          if (index == -1) {
            updatePerformed = true
            cartItems.push({
              ...item,
              quantity: 1,
            })
          } else {
            updatePerformed = true
            cartItems[index].quantity += 1
          }
          break
        }
        case 'decrement': {
          const cartItems = cartData.items
          const index = cartItems.findIndex((item) => this.compareItems(item, event.item))
          if (cartItems[index].quantity == 1) {
            updatePerformed = this.removeItem(cartData, event.item, updatePerformed)
          } else {
            if (index != -1) {
              updatePerformed = true
              cartItems[index].quantity -= 1
              if (cartItems[index].quantity <= 0) {
                cartData.items.splice(index, 1)
              }
            }
          }
          break
        }
        case 'upsert': {
          const cartItems = cartData.items
          const index = cartItems.findIndex((item) => this.compareItems(item, event.item))
          const { item, quantity } = event
          if (index == -1) {
            updatePerformed = true
            cartItems.push({
              ...item,
              quantity,
            })
          } else if (cartItems[index].quantity != quantity) {
            if (quantity == 0) {
              updatePerformed = this.removeItem(cartData, event.item, updatePerformed)
            } else {
              updatePerformed = true
              cartItems[index].quantity = quantity
            }
          }
          break
        }
        case 'remove': {
          updatePerformed = this.removeItem(cartData, event.item, updatePerformed)
          break
        }
        case 'replace': {
          const cartItems = cartData.items
          const index = cartItems.findIndex((item) => this.compareItems(item, event.item))
          const newItemIndex = cartItems.findIndex((item) => this.compareItems(item, event.newItem))
          if (index > -1) {
            const currentCartItem = cartItems[index]
            updatePerformed = true
            if (newItemIndex > -1 && index != newItemIndex) {
              const currentItemQuantity = currentCartItem.quantity
              cartItems[newItemIndex].quantity += currentItemQuantity
              cartItems.splice(index, 1)
            } else {
              cartItems[index] = {
                ...currentCartItem,
                ...event.newItem,
              }
            }
          }
          break
        }
      }
    })

    if (updatePerformed) {
      unsynchronizedEvents.push(
        ...this.operations.map((op) => {
          // NOTE: We need to give a uuid for each event in order to not process any event twice
          // in the server in case of a partition.
          op.id = uuidv4()
          return op
        })
      )
      syncCartWithServerThrottled()

      this.subscribers.forEach((sub) => {
        sub(this.operations)
      })

      globalThis.globalResourceData.cart = { ...cartData }
      Cart.stores.cartData.set({ ...cartData })
    }
    this.operations = []
  }
}

Cart.initialize()
