export function getIn<K>(obj: any, path: string | string[], defaultValue?: K): K | null {
  if (obj === null) return defaultValue
  if (typeof obj !== 'object') return defaultValue

  const pathArray = Array.isArray(path) ? path : path.split('.')
  let result = obj
  for (const key of pathArray) {
    result = result[key]
    if (result === undefined) return defaultValue ?? null
  }
  return (result as unknown) as K
}

const reIsUint = /^(?:0|[1-9]\d*)$/

export function isIndex(value: number | string): boolean {
  const type = typeof value
  return type === 'number' || (type !== 'symbol' && reIsUint.test(type))
}

export function setIn(obj: any, path: string | string[], value: any): any {
  if (obj === null) return obj
  if (typeof obj !== 'object') return obj

  const pathArray = Array.isArray(path) ? path : path.split('.')
  let nested = obj
  const [lastKey] = pathArray.slice(-1)
  const pathToObj = pathArray.slice(0, -1)

  for (const key of pathToObj) {
    if (nested[key] === undefined) {
      if (isIndex(key)) {
        nested[key] = []
      } else {
        nested[key] = {}
      }
    }
    nested = nested[key]
  }
  nested[lastKey] = value

  return obj
}

export function unsetIn(obj: any, path: string | string[]): void {
  if (obj === null) return
  if (typeof obj !== 'object') return

  const pathArray = Array.isArray(path) ? path : path.split('.')
  let nested = obj
  const [lastKey] = pathArray.slice(-1)
  const pathToObj = pathArray.slice(0, -1)

  for (const key of pathToObj) {
    if (nested[key] === undefined) return
    nested = nested[key]
  }
  delete nested[lastKey]
}

export function assignIn(obj: any, ...sources: any[]): any {
  sources.forEach((source) => {
    if (source) {
      for (const key in source) {
        obj[key] = source[key]
      }
    }
  })
  return obj
}

export const camelToSnakeCase = (property: string): string => {
  const pattern = /([A-Z])/g
  return property.replace(pattern, (_, char) => `_${char.toLowerCase()}`)
}

export const camelToKebabCase = (property: string): string => {
  const pattern = /([A-Z])/g
  return property.replace(pattern, (_, char) => `-${char.toLowerCase()}`)
}

const htmlUnescapes = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#34;': '"',
  '&#39;': "'",
}

/* -------------------------------------------------------------------
From: https://github.com/lodash/lodash/blob/main/src/unescape.ts
---------------------------------------------------------------------*/

const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?(39|34));/g
const reHasEscapedHtml = RegExp(reEscapedHtml.source)

export function unescape(string: string): string {
  return string && reHasEscapedHtml.test(string)
    ? string.replace(reEscapedHtml, (entity) => htmlUnescapes[entity] || "'")
    : string || ''
}

/* -------------------------------------------------------------------
From: https://github.com/lodash/lodash/blob/main/src/escapeRegExp.ts
---------------------------------------------------------------------*/
const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
const reHasRegExpChar = RegExp(reRegExpChar.source)

export function escapeRegExp(string: string): string {
  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string || ''
}

/* -------------------------------------------------------------------
From: https://github.com/lodash/lodash/blob/main/src/throttle.ts
---------------------------------------------------------------------*/

type DebouceOptions = {
  maxWait?: number
  leading?: boolean
  trailing?: boolean
}

export function isObject(value: any): boolean {
  const type = typeof value
  return value !== null && (type === 'object' || type === 'function')
}

export function throttle<Fn extends (...args: any[]) => any>(func: Fn, wait: number, options?: DebouceOptions): Fn {
  let leading = true
  let trailing = true

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function')
  }
  if (isObject(options)) {
    leading = 'leading' in options ? !!options.leading : leading
    trailing = 'trailing' in options ? !!options.trailing : trailing
  }
  return debounce(func, wait, {
    leading,
    trailing,
    maxWait: wait,
  })
}

export function debounce<Fn extends (...args: any[]) => any>(func: Fn, wait: number, options?: DebouceOptions): Fn {
  let lastArgs
  let lastThis
  let maxWait
  let result: ReturnType<Fn>
  let timerId
  let lastCallTime
  let lastInvokeTime = 0
  let leading = false
  let maxing = false
  let trailing = true

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function')
  }
  wait = +wait || 0
  if (isObject(options)) {
    leading = !!options.leading
    maxing = 'maxWait' in options
    maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait
    trailing = 'trailing' in options ? !!options.trailing : trailing
  }

  function invokeFunc(time) {
    const args = lastArgs
    const thisArg = lastThis

    lastArgs = lastThis = undefined
    lastInvokeTime = time
    result = func.apply(thisArg, args)
    return result
  }

  function startTimer(pendingFunc, milliseconds) {
    return setTimeout(pendingFunc, milliseconds)
  }

  function cancelTimer(id) {
    clearTimeout(id)
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time
    // Start the timer for the trailing edge.
    timerId = startTimer(timerExpired, wait)
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime
    const timeWaiting = wait - timeSinceLastCall

    return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      (maxing && timeSinceLastInvoke >= maxWait)
    )
  }

  function timerExpired() {
    const time = Date.now()
    if (shouldInvoke(time)) {
      return trailingEdge(time)
    }
    // Restart the timer.
    timerId = startTimer(timerExpired, remainingWait(time))
    return undefined
  }

  function trailingEdge(time) {
    timerId = undefined

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time)
    }
    lastArgs = lastThis = undefined
    return result
  }

  function cancel() {
    if (timerId !== undefined) {
      cancelTimer(timerId)
    }
    lastInvokeTime = 0
    lastArgs = lastCallTime = lastThis = timerId = undefined
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now())
  }

  function pending() {
    return timerId !== undefined
  }

  function debounced(...args: Parameters<Fn>): ReturnType<Fn> {
    const time = Date.now()
    const isInvoking = shouldInvoke(time)

    lastArgs = args
    lastThis = this
    lastCallTime = time

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime)
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = startTimer(timerExpired, wait)
        return invokeFunc(lastCallTime)
      }
    }
    if (timerId === undefined) {
      timerId = startTimer(timerExpired, wait)
    }
    return result
  }
  debounced.cancel = cancel
  debounced.flush = flush
  debounced.pending = pending
  return (debounced as undefined) as Fn
}

export function flattenDeep<T>(array: T[], result?: T[]): T[] {
  const length = array == null ? 0 : array.length
  if (!length) return []

  result || (result = [])

  if (array == null) {
    return result
  }

  for (const value of array) {
    if (Array.isArray(value)) {
      flattenDeep(value, result)
    } else {
      result.push(value)
    }
  }
  return result
}

export function isArrayEqual<T>(array: T[], compare: T[]): boolean {
  if (array.length !== compare.length) return false
  for (let i = 0; i < array.length; i++) {
    if (isObject(array[i])) {
      if (!isObjectEqual(array[i], compare[i])) {
        return false
      }
    } else {
      if (array[i] !== compare[i]) {
        return false
      }
    }
  }
  return true
}

export function isObjectEqual(obj1: any, obj2: any): boolean {
  if (Array.isArray(obj1) && Array.isArray(obj2)) return isArrayEqual(obj1, obj2)

  if (!isObject(obj1) || !isObject(obj2)) {
    return false
  }

  if (obj1 === obj2) {
    return true
  }

  const item1Keys = Object.keys(obj1).sort()
  const item2Keys = Object.keys(obj2).sort()

  if (!isArrayEqual(item1Keys, item2Keys)) {
    return false
  }

  return item2Keys.every((key) => {
    const value = obj1[key]
    const nextValue = obj2[key]

    if (isObject(obj1[key])) {
      return isObjectEqual(obj1[key], obj2[key])
    } else {
      if (value === nextValue) {
        return true
      }
    }

    return Array.isArray(value) && Array.isArray(nextValue) && isArrayEqual(value, nextValue)
  })
}

export const isEqual = (value1: any, value2: any): boolean => {
  if (isObject(value1) && isObject(value2)) {
    return isObjectEqual(value1, value2)
  }

  return value1 === value2
}
